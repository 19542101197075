import React from 'react';

import * as attributeColors from '../../../theme/scss/export/attribute-colors.module.scss';

const AttributeColorListView = (props) => {
    const { colorMap } = props;

    return (
        <>
            <div className="w-100">
                <div className="d-flex flex-wrap">
                    {Array.from(Object.entries(colorMap)).map(
                        ([name, value]) => (
                            <div key={name} className="mr-4 mb-5 text-center">
                                <div
                                    className="mb-2"
                                    style={{
                                        background: value,
                                        width: '4.5rem',
                                        height: '4.5rem',
                                        border: '1px solid #e6e6e6'
                                    }}
                                />
                                {name}
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

const AttributeColorList = () => (
    <AttributeColorListView colorMap={attributeColors} />
);

export { AttributeColorList };
