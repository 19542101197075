import React from 'react';

import * as breakpoints from '../../../theme/scss/export/breakpoints.module.scss';

const Breakpoints = () => {
    const sortedBreakpoints = Array.from(Object.entries(breakpoints))
        .sort((a, b) => {
            const aValue = parseInt(a[1], 10);
            const bValue = parseInt(b[1], 10);
            if (aValue < bValue) return 1;
            if (aValue > bValue) return -1;
            return 0;
        })
        .map(([name, width]) => ({ name, width }));

    return (
        <BreakpointBox
            breakpoints={sortedBreakpoints}
            classNameFn={(level) =>
                `position-relative pb-5 bg-gray-${(level % 8) * 100 + 100}`
            }
            labelClassName="text-right m-4 position-absolute"
            labelStyle={{ right: 0, bottom: 0 }}
        />
    );
};

const BreakpointBox = ({
    breakpoints,
    lastWidth,
    level = 0,
    classNameFn,
    labelClassName,
    labelStyle
}) => {
    const [currentBreakpoint, ...nextBreakpoints] = breakpoints;
    return currentBreakpoint ? (
        <div
            className={classNameFn ? classNameFn(level) : ''}
            style={{
                width: lastWidth || '100%',
                height: lastWidth
                    ? `${parseInt(lastWidth, 10) * 0.5}px`
                    : '100%'
            }}>
            {nextBreakpoints.length > 0 && (
                <BreakpointBox
                    breakpoints={nextBreakpoints}
                    lastWidth={currentBreakpoint.width}
                    level={level + 1}
                    classNameFn={classNameFn}
                    labelClassName={labelClassName}
                    labelStyle={labelStyle}
                />
            )}
            <h2 className={labelClassName} style={labelStyle}>
                {currentBreakpoint.name}
                <br />
                {lastWidth}
            </h2>
        </div>
    ) : null;
};

export default Breakpoints;
