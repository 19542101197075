import React from 'react';

const boxFail = `.box {
  border-top: 1px solid color(danger);
  border-bottom: 1px solid color(danger);
  padding: rhythm(2) 0;
}`;

const boxOk = `.box {
  border-top: 1px solid color(success);
  border-bottom: 1px solid color(success);
  padding-top: rhythm(2, $offset: 1px);
  padding-bottom: rhythm(2, $offset: 1px);
}`;

export default function SpacingBorder() {
    return (
        <>
            <h3 style={{ color: '#da3530' }}>Incorrect:</h3>
            <pre>{boxFail}</pre>
            <p>
                Total height will now be 30px (14px + 14px + 1px + 1px) which
                will break the rhythm since 30 is not dividable by 7. Notice how
                the box no longer aligns with the lines:
            </p>
            <div className="vertical-grid vertical-grid--small">
                <div className="spacing-box--incorrect" />
            </div>
            <h3 style={{ color: '#008059' }}>Correct:</h3>
            <pre>{boxOk}</pre>
            <p>
                We subtract the borders (1+1px) from the padding which leaves us
                with a total correct height of 28px which is dividable by 7.
            </p>
            <div className="vertical-grid vertical-grid--small">
                <div className="spacing-box--correct" />
            </div>
        </>
    );
}
