import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const ColoredIconsList = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allFile(
                        filter: {
                            sourceInstanceName: { regex: "/icons-colored/" }
                        }
                        sort: { fields: name }
                    ) {
                        edges {
                            node {
                                name
                                publicURL
                            }
                        }
                    }
                }
            `}
            render={(data) => <ColoredIconView data={data} {...props} />}
        />
    );
};

function ColoredIconView(props) {
    const logos = props.data.allFile.edges.map((edge) => edge.node);

    return (
        <div className="row">
            {logos.map((logo) => (
                <div key={logo.name} className="col-4 p-2 text-center">
                    <img
                        src={logo.publicURL}
                        className="icon"
                        style={{ width: '90%', height: '10rem' }}
                        alt=""
                    />
                    <p>{logo.name}</p>
                </div>
            ))}
        </div>
    );
}

export default ColoredIconsList;
