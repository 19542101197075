import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';
import kebabCase from 'lodash/kebabCase';

import Page from '../components/page';
import { AttributeColorList } from '../components/examples/color-list';
import IconList from '../components/examples/icon-list';
import ColoredIconsList from '../components/examples/colored-icons-list';
import LogoList from '../components/examples/logo-list';
import HtmlTooltip from '../components/examples/tooltip';
import Breakpoints from '../components/examples/breakpoints';
import Spacing from '../components/examples/spacing';
import SpacingBorder from '../components/examples/spacing-border';

// Convert rendered markdown to components
// https://using-remark.gatsbyjs.org/custom-components/
const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
        // Article example components
        'attribute-color-list': AttributeColorList,
        'icon-list': IconList,
        'logo-list': LogoList,
        'colored-icons-list': ColoredIconsList,
        'html-tooltip-example': HtmlTooltip,
        'breakpoints-example': Breakpoints,
        spacing: Spacing,
        'spacing-border': SpacingBorder
    }
}).Compiler;

const ArticleTemplate = (props) => {
    const post = props.data.markdownRemark;
    const showContentLinks = !['fullwidth', 'maxwidth'].includes(
        post.frontmatter.layout
    );
    return (
        <Fragment>
            <Helmet>
                <title>{post.frontmatter.title}</title>
            </Helmet>
            <Page layout={post.frontmatter.layout}>
                <div className="row">
                    <div
                        className={`page-content ${
                            showContentLinks ? 'col-lg-10 col-12' : 'col-12'
                        }`}>
                        <h1 className="text-styled mb-5">
                            {post.frontmatter.title}
                        </h1>
                        {renderAst(post.htmlAst)}
                    </div>
                    {showContentLinks && (
                        <div className="col-2 d-lg-block d-none">
                            {post.headings.map(
                                (heading) =>
                                    heading.depth > 1 && (
                                        <p
                                            key={heading.value}
                                            className={`pl-${Math.min(
                                                (heading.depth - 1) * 2,
                                                5
                                            )}`}>
                                            <a
                                                href={`#${kebabCase(
                                                    heading.value
                                                )}`}>
                                                {heading.value}
                                            </a>
                                        </p>
                                    )
                            )}
                        </div>
                    )}
                </div>
            </Page>
        </Fragment>
    );
};

export default ArticleTemplate;

export const pageQuery = graphql`
    query PageBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            headings {
                value
                depth
            }
            htmlAst
            frontmatter {
                title
                layout
            }
        }
    }
`;
