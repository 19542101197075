/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

const IconList = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allFile(
                        filter: {
                            sourceInstanceName: {
                                regex: "/icons2|icons-colored|icons-animated/"
                            }
                        }
                        sort: { fields: name }
                    ) {
                        edges {
                            node {
                                name
                                publicURL
                                sourceInstanceName
                            }
                        }
                    }
                }
            `}
            render={(data) => <IconListView data={data} {...props} />}
        />
    );
};

const IconListView = (props) => {
    const [filter, setFilter] = useState('');
    const [selected, setSelected] = useState(null);
    const [selectedClasses, setSelectedClasses] = useState({ base: 'icon' });
    const [selectedClassString, setSelectedClassString] = useState('icon');
    const [selectedHtml, setSelectedHtml] = useState('');
    const selectedIconContainer = useRef(null);
    const icons = props.data.allFile.edges
        .map((edge) => edge.node)
        .filter((icon) => !filter || icon.name.includes(filter));

    const handleFilter = ({ target: { value } }) => {
        setFilter(value);
    };

    const handleSelect = (selected) => {
        setSelected(selected);

        // Load svg file and inject as html
        fetch(selected.publicURL)
            .then((response) => response.text())
            .then((html) => {
                setSelectedHtml(html);
                updateSelectedIconClasses();
            });
    };

    const handleIconClassUpdate = (event) => {
        const {
            target: { name, value }
        } = event;

        const classes = {
            ...selectedClasses,
            [name]: value
        };

        const selectedClassString = Array.from(Object.values(classes))
            .filter((c) => c)
            .join(' ');

        setSelectedClasses(classes);
        setSelectedClassString(selectedClassString);
    };

    const updateSelectedIconClasses = useCallback(() => {
        const svgElement = selectedIconContainer.current.querySelector('svg');

        if (svgElement) {
            svgElement.setAttribute('class', selectedClassString);
        }
    }, [selectedClassString]);

    const handleIconClassReset = () => {
        setFilter('');
        setSelected(null);
        setSelectedClasses({ base: 'icon' });
        setSelectedClassString('icon');
    };

    const getIconCode = (selectedIcon, classes) => {
        const { name, sourceInstanceName } = selectedIcon;
        const formattedName = upperFirst(camelCase(name));

        return `import ${formattedName}Icon from 'tradera/blueprint/theme/${sourceInstanceName}/${name}.svg?jsx';\n\n<${formattedName}Icon className="${classes}" />`;
    };

    const selectedIconCode = selected
        ? getIconCode(selected, selectedClassString)
        : '';

    useEffect(() => {
        updateSelectedIconClasses();
    }, [selectedClassString, updateSelectedIconClasses]);

    return (
        <div className="row">
            <div className="col-8">
                <div className="d-flex flex-row">
                    <div className="pr-2">
                        <label htmlFor="iconFilter" className="d-inline-block">
                            Filter
                        </label>
                    </div>
                    <div>
                        <input
                            type="text"
                            name="iconFilter"
                            className="form-control mb-4"
                            value={filter}
                            onChange={handleFilter}
                        />
                    </div>
                </div>
                <div className="row">
                    {icons.map((icon, i) => (
                        <button
                            key={icon.name + i}
                            className={`col-2 p-2 text-center ${
                                selected &&
                                selected.name === icon.name &&
                                selected.sourceInstanceName ===
                                    icon.sourceInstanceName
                                    ? 'bg-secondary'
                                    : ''
                            }`}
                            onClick={() => handleSelect(icon)}
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none'
                            }}>
                            <img
                                src={icon.publicURL}
                                className="icon icon-lg"
                                alt=""
                            />
                            <p>{icon.name}</p>
                        </button>
                    ))}
                </div>
            </div>

            <div className="col-4 text-center">
                {/* eslint-disable-next-line react/no-danger */}
                <div
                    style={{ height: '4rem' }}
                    ref={selectedIconContainer}
                    dangerouslySetInnerHTML={{ __html: selectedHtml }}
                />
                <form>
                    <div className="mb-4">
                        <label htmlFor="iconSize">Size</label>
                        <select
                            className="form-control single-select w-100"
                            name="iconSize"
                            value={selectedClasses.iconSize || ''}
                            onChange={handleIconClassUpdate}>
                            <option value=""> </option>
                            <option value="icon-xs">xs</option>
                            <option value="icon-md">md</option>
                            <option value="icon-lg">lg</option>
                            <option value="icon-xl">xl</option>
                            <option value="icon-xxl">xxl</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="iconColor">Fill color</label>
                        <select
                            className="form-control single-select w-100"
                            name="iconColor"
                            onChange={handleIconClassUpdate}>
                            <option value=""> </option>
                            <option value="icon-primary">primary</option>
                            <option value="icon-secondary">secondary</option>
                            <option value="icon-branded">branded</option>
                            <option value="icon-success">success</option>
                            <option value="icon-danger">danger</option>
                            <option value="icon-white">white</option>
                            <option value="icon-dark">dark</option>
                            <option value="icon-gray-100">gray-100</option>
                            <option value="icon-gray-200">gray-200</option>
                            <option value="icon-gray-300">gray-300</option>
                            <option value="icon-gray-400">gray-400</option>
                            <option value="icon-gray-500">gray-500</option>
                            <option value="icon-gray-600">gray-600</option>
                            <option value="icon-gray-700">gray-700</option>
                            <option value="icon-gray-800">gray-800</option>
                            <option value="icon-gray-900">gray-900</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="iconColor">Stroke color</label>
                        <select
                            className="form-control single-select w-100"
                            name="iconStroke"
                            onChange={handleIconClassUpdate}>
                            <option value=""> </option>
                            <option value="icon-stroke-white">white</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="iconAnimation">Animation</label>
                        <select
                            className="form-control single-select w-100"
                            name="iconAnimation"
                            onChange={handleIconClassUpdate}>
                            <option value="">(none)</option>
                            <option value="icon-spin">spin</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <button
                            type="button"
                            className="btn btn-primary w-100-reset"
                            onClick={handleIconClassReset}>
                            Reset
                        </button>
                    </div>
                    <h6 className="mt-3">Import</h6>
                    <p>
                        <textarea
                            readOnly={true}
                            value={selectedIconCode}
                            className="form-control w-100"
                            rows={5}
                        />
                    </p>
                    <h6 className="mt-3">SVG</h6>
                    <p>
                        <textarea
                            readOnly={true}
                            value={selectedHtml}
                            className="form-control w-100"
                            rows={5}
                        />
                    </p>
                    <p>
                        {selected && (
                            <a
                                href={selected.publicURL}
                                download={`${selected.name}.svg`}
                                className="btn btn-primary w-100">
                                Download icon file
                            </a>
                        )}
                    </p>
                </form>
            </div>
        </div>
    );
};

export default IconList;
