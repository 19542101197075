import React from 'react';

const box = {
    width: '84px',
    marginBottom: '14px',
    opacity: 0.7
};

const smallSpacing = {
    ...box,
    height: '14px',
    backgroundColor: '#6265b3'
};

const mediumSpacing = {
    ...box,
    height: '28px',
    backgroundColor: '#d8d8d8'
};

const largeSpacing = {
    ...box,
    height: '49px',
    backgroundColor: '#ff8e8e'
};

const square = (color) => {
    return {
        height: '28px',
        width: '14px',
        backgroundColor: color,
        float: 'left',
        marginRight: '7px'
    };
};

const code = (text) => (
    <code style={{ marginBottom: '21px', display: 'block' }}>{text}</code>
);

export default function Spacing() {
    return (
        <div style={{ marginTop: '21px' }}>
            <h3>
                <div style={square('#6265b3')} />
                Spacing within elements
            </h3>
            {code('rhythm(2) // 14px')}

            <h3>
                <div style={square('#d8d8d8')} />
                Spacing between elements
            </h3>
            {code('rhythm(4) // 28px')}

            <h3>
                <div style={square('#ff8e8e')} />
                Spacing between sections
            </h3>
            {code('rhythm(7) // 49px')}

            <div
                className="vertical-grid vertical-grid--small"
                style={{ height: '150px' }}>
                <div style={smallSpacing} />
                <div style={mediumSpacing} />
                <div style={largeSpacing} />
            </div>
        </div>
    );
}
