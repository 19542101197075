// extracted by mini-css-extract-plugin
export var beige = "#d4be8d";
export var black = "#000";
export var blue = "#3f99fa";
export var bronze = "linear-gradient(to bottom, #ad845c, #f5d3b1 51%, #ad845c)";
export var brown = "#885421";
export var gold = "linear-gradient(to bottom, #ce9501, #ffcc48 51%, #ce9501)";
export var gray = "#aaa";
export var green = "#90d43b";
export var orange = "#fb863b";
export var pink = "#ff57cf";
export var purple = "#9c56ff";
export var red = "#e70f2e";
export var silver = "linear-gradient(to bottom, #6f6f6f, #b7b7b7 51%, #6f6f6f)";
export var turquoise = "#38cbce";
export var white = "#fff";
export var yellow = "#ffcf3c";